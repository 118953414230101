.main {
	height:100%;

}

.carousel,
.carousel-item,
.active{height:100%;}
.carousel-inner{height:100%;}
.fill{
	width:100%;
	height:100%;
	background-position:center;
	background-size:contain;
	background-repeat: no-repeat;
	@include media-breakpoint-up(lg){
		background-size:cover;
	}
}

#juan{
	position: fixed;
	z-index: 1040;

	bottom: 1.5rem;
	left: 50%;
	transform: translateX(-50%);

	text-transform: uppercase;

	cursor:pointer;
	&.white{
		color:white;
	}

	@include media-breakpoint-up(lg){
		top: 1.85rem;
		left: 2.5rem;
		bottom: initial;
		transform: translateX(0);
	}
}
.info{
	position: fixed;
	z-index: 1030;

	top:0;
	left: 0;
	right: 0;
	bottom: 0;

	background-color: black;
	color: white;

	transform: translateY(100%);
	transition: transform .5s cubic-bezier(.77,.2,.05,1);

	padding: 1.5rem;

	.content{
		width: 100%;
		height: 100%;
		max-width: 85rem;
		@include media-breakpoint-up(lg){
			margin-top: 5rem;
		}
	}

	&.active{
		transform: translateY(0%)!important;
	}
	@include media-breakpoint-up(lg){
		padding: 2.5rem;
	}

}
body{
	&,.carousel-caption{
		color: black;
	}
	
	@include media-breakpoint-up(lg){
		&.black_text{
			&,.carousel-caption{
				color: black;
			}
		}
		&.white_text{
			&,.carousel-caption{
				color: white;
			}
		}
	}

}

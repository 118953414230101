.carousel-caption{
	top: 1.5rem;
	right: 0;
	bottom: initial;
	left: 0;

	padding:0 2.5rem;

	text-align: center;
	span{
		display:block;
		@include media-breakpoint-up(lg){
			display:inline-block;
		}
	}
}
.carousel-control-prev,
.carousel-control-next{
	width: 50%;
	opacity: 1;
}
body{

	@include media-breakpoint-up(lg){
		&.black_text{
			.carousel-control-prev{
				&:hover{
					cursor: url('../img/site/previous_b.svg') -88 7,none;
				}
			}
			.carousel-control-next{
				&:hover{
					cursor: url('../img/site/next_b.svg') 88 7,none;
				}
			}

		}
		&.white_text{
			.carousel-control-prev{
				&:hover{
					cursor: url('../img/site/previous_w.svg') -48 7,none;
				}
			}
			.carousel-control-next{
				&:hover{
					cursor: url('../img/site/next_w.svg') 48 7,none;
				}
			}


		}
	}


}
.carousel-control-prev{
	justify-content:end;
	margin-left: 0.5rem;
	&:hover{
		cursor: url('../img/site/previous_w.svg') -48 7,none;
	}
}
.carousel-control-next{
	justify-content:flex-end;
	margin-right: 0.5rem;
	&:hover{
		cursor: url('../img/site/next_w.svg') 48 7,none;
	}
}

.carousel-control-prev-icon,
.carousel-control-next-icon{
	background: initial;
	width:auto;
	img{
		width: 1.2rem;
	}
}

* {
  box-sizing: border-box;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
html {
  font-size: 62.5%;
}
body {
  font: 300 13px/1.6 'Helvetica Neue', Helvetica, Arial;
  color: white;
  //transition: all .4s .3s ease-in;
}
body,html,#juansFunCarousel{
	height: 100%;
	width:100vw;
	overflow: hidden;
}
//
//::selection{
//	background: transparent;
//	color: rgba(255,255,255,1);
//	text-shadow: yellow 1px 0 10px;
//
//}
//::-moz-selection{
//	background: transparent;
//	color: rgba(255,255,255,1);
//	text-shadow: yellow 1px 0 10px;
//
//}
//::-webkit-selection{
//	background: transparent;
//	color: rgba(255,255,255,1);
//	text-shadow: yellow 1px 0 10px;
//
//}

::selection{
	background: transparent;
	color: rgba(255,255,255,0.1);

}
::-moz-selection{
	background: transparent;
	color: rgba(255,255,255,0.1);

}
::-webkit-selection{
	background: transparent;
	color: rgba(255,255,255,0.1);

}

a:link,
a:visited,
a:active,
a:hover{
	color: white;
	text-decoration: underline;
}
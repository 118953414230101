.work{
	text-transform: uppercase;
	margin-right: 0;

	@include media-breakpoint-up(lg){
		margin-right: 2rem;
	}
}
.caption{
	@include media-breakpoint-up(lg){
		margin-right: 2rem;
	}
}
.credits{
	text-transform: uppercase;
}
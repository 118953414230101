@font-face {
  font-family: 'Font-Name';
  src: url('../fonts/font-name.eot');
  src: url('../fonts/font-name.eot?#iefix') format('embedded-opentype'),
       url('../fonts/font-name.woff') format('woff'),
       url('../fonts/font-name.ttf') format('truetype'),
       url('../fonts/font-name.svg#font-name') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
	font-family: 'FG';
	src: url('../fonts/FoundersGroteskRegular.eot');
	src: url('../fonts/FoundersGroteskRegular.eot?#iefix') format('embedded-opentype'),
	url('../fonts/FoundersGroteskRegular.woff') format('woff'),
	url('../fonts/FoundersGroteskRegular.ttf') format('truetype'),
	url('../fonts/FoundersGroteskRegular.svg#font-name') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'TN';
	src: url('../fonts/TimesNow-SemiLight.eot');
	src: url('../fonts/TimesNow-SemiLight.eot?#iefix') format('embedded-opentype'),
	url('../fonts/TimesNow-SemiLight.woff') format('woff'),
	url('../fonts/TimesNow-SemiLight.ttf') format('truetype'),
	url('../fonts/TimesNow-SemiLight.svg#font-name') format('svg');
	font-weight: normal;
	font-style: normal;
}

#juan,
.carousel-caption{
    font-size: 1.6rem;
    line-height: 1.9rem;
	@include media-breakpoint-up(lg){
		font-size: 1.4rem;
		line-height: 1.5rem;
	}
}
.info{
	font-size: 1.5rem;
	line-height: 1.7rem;

	@media (min-width:321px){
		font-size: 1.6rem;
		line-height: 1.9rem;
	}
	@include media-breakpoint-up(lg){
		font-size: 2.5rem;
		line-height: 2.5rem;
	}
	p{
		margin-bottom:1.5rem;
		@media (min-width:321px){
			margin-bottom:1.9rem;
		}
		@include media-breakpoint-up(lg){
			margin-bottom: 2.5rem;
		}
	}
}
body{
	font-family: $font-fg;
}
.fg{
	font-family: $font-fg;
}
.tn{
	font-family: $font-tn;
}
.credits,
.work{
	font-family: $font-fg;
}
.caption{
	font-family: $font-tn;
	font-size: 1.85rem;
	line-height: 2.1rem;
}